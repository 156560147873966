@import "colors.scss";

.MuiTableRow-root .MuiTableCell-head {
	font-weight: bold;
}

.MuiTableCell-root {
	padding: 10px !important;
}

.without-padding input {
	padding: 0 !important;
}

.text-center,
.text-center input {
	text-align: center;
}

div[role="tooltip"] {
	white-space: pre-line;
}
