@import "main.scss";

.main-container {
	margin-top: 100px !important;
	background: $white;
}
.drawer--logo-container {
	background-color: $ruby;
	color: $white;
	height: 70px;
}
.drawer--logo-container .logo-img {
	align-items: center;
}
.drawer--logo-container button {
	color: $white;
}
.drawer--logo-container img {
	height: 60px;
}
.SideMenuContainer header {
	justify-content: center;
	background-color: $white;
	color: $black;
}
.logout-container button {
	color: $ruby;
}
.menu-item-icon.MuiListItemIcon-root,
.menu-item-text {
	color: $ruby;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: rgba(253, 179, 5, 0.71) !important;
}
.MuiListItem-button:hover {
	background-color: rgba(255, 179, 0, 0.34) !important;
}
.menu-item-text .MuiTypography-body1 {
	font-weight: 600;
	font-size: 15px;
}
.menu-page-title.MuiTypography-h5 {
	color: $ruby;
	font-weight: 600;
	font-size: 20px;
}
.not-found-page-container {
	background: $white;
	height: 100%;
	width: 100%;
	position: fixed;

	.MuiButton-contained.home-btn {
		background-color: $ruby;
		color: $white;
		font-weight: 600;
		padding: 10px 20px;
		margin: 20px 0;
	}
	img {
		max-width: 100%;
	}
	.not-found-page-content {
		padding: 0 5%;
	}
	.not-found-page-logo {
		width: 200px;
		padding: 20px 10px;
	}
}

.success .MuiSnackbarContent-root {
	background-color: $success;
	color: $white;
}
.app-loader {
	background: url("../img/loading-logo.gif") no-repeat $ruby 50%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-moz-background-size: 196px 196px;
	-o-background-size: 196px 196px;
	-webkit-background-size: 196px 196px;
	background-size: 196px 196px;
	z-index: 99998;
	width: 100%;
	height: 100%;
}
.MuiIconButton-root.return-btn {
	color: $ruby;
}
.main-container {
	width: 100%;
}
.MuiTab-textColorSecondary {
	font-weight: bold !important;
}
select.MuiSelect-select option:hover,
select.MuiSelect-select option:focus,
select.MuiSelect-select option:active,
select.MuiSelect-select option:checked {
	background-color: $golden-yellow !important;
}
#select-multiple-native.MuiSelect-select.MuiSelect-select {
	padding-right: 0 !important;
}

.MuiFormControl-root .MuiFormLabel-root.Mui-focused {
	color: $ruby;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: $golden-yellow !important;
}
