@import "main.scss";

.login {
	height: 100vh;
	background-image: url("../img/background.png");
	background-repeat: no-repeat;
	background-size: cover;
}
.MuiButton-contained.login-btn {
	background-color: $ruby;
	color: $white;
	font-weight: 600;
}
