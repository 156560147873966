$black: #000;
$ruby: #cc0066;
$white: #fff;
$transparent-white: rgba(255, 255, 255, 0.29);
$ice-blue: #f5f6f6;
$very-light-pink: #e2e2e2;
$dark: #161b1d;
$bluey-grey: #8d979f;
$ice-blue-dark: #ebeef0;
$slate-grey: #5b6770;
$dark-grey: #888;
$medium-grey: #cbd3d8;
$light-grey: #e6e6e6;
$tomato-red: #e93800;
$alice-blue: #f0f8ff;
$green: #16ac3f;
$golden-yellow: #f6c124;
$divider-color: #d4cbc7;
$subrow-color: #fafafa;
$black-shadow: #8f8f8f;
$red-error: #c92222;
$burnt-orange: #f37836;
$vivid-pink: #c3075f;
$success: #50b147;
